<template>
  <div>
    <div class="erp-form__header op-16" style="margin-bottom: 30px; margin-top: 12px">
      <div class="erp-form__title">选择收费细目</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">确定</el-button>
        <div class="erp-default__btn" @click="back">取消</div>
      </div>
    </div>
    <el-radio-group v-model="checkId" style="width: 100%" class="no-label erp-radio">
      <el-table :data="formData" border class="egrid erp-table__list erp-view__table">
        <el-table-column label align="center" width="40" class-name="no-pd">
          <template #default="scope">
            <el-radio :label="scope.$index" :disabled="scope.row.isPayedAmountBiggerThenAmount">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="费用类型" class-name="no-pd" align="center" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.feeTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="金额(人民币)" class-name="no-pd" align="center" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.amountRmbDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" class-name="no-pd" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" class-name="no-pd" width="180" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.applyTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="varietyName" align="center" class-name="no-pd" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.applyOperator) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: [Object],
    },
  },
  data() {
    return {
      checkId: null,
      formData: [],
    };
  },
  methods: {
    getIncidentalsList(id, type) {
      let url = null;
      if (type === 3) {
        url = '/malicrm/orderArap/queryArapTaxFee';
      } else {
        url = '/malicrm/orderArap/queryArapFee';
      }
      this.ajax({
        type: 'POST',
        url: url,
        data: {
          orderId: id,
          type: 1,
        },
        loading: true,
        success: (res) => {
          this.formData = res.result;
        },
      });
    },
    /**
     * 提交事件
     */
    submit() {
      if (this.checkId === null) {
        this.errorTip('请选择一条费用');
      } else {
        const data = this.formData.filter((v, index) => index === this.checkId)[0];
        this.$emit('checked', data);
        this.checkId = null;
      }
    },
    back() {
      // 返回操作
      this.checkId = null;
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
