<template>
  <div>
    <el-select
      v-model="businessContractNo"
      placeholder
      :remote-method="
        (name) => {
          getBusinessContract(name, null);
        }
      "
      filterable
      remote
      value-key="orderId"
      @change="changeBusinessContract"
    >
      <el-option v-for="item in businessContractList" :key="item.orderId" :label="item.businessContractNo" :value="item"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ['value', 'index'],
  data() {
    return {
      businessContractNo: null,
      businessContractList: [],
    };
  },
  watch: {
    value: {
      handler() {
        console.log(this.value);
        if (this.value) {
          this.businessContractNo = JSON.parse(JSON.stringify(this.value));
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.businessContractNo && this.businessContractNo.orderId) {
      const item = JSON.parse(JSON.stringify(this.value));
      this.getBusinessContract('', item);
    } else {
      // this.getBusinessContract('');
    }
  },
  methods: {
    getBusinessContract(name, item) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/order/queryActiveOrder',
        data: {
          businessContractNo: name,
          isFundingType: false,
        },
        loading: true,
        success: (res) => {
          this.businessContractList = res.result;
          console.log(res.result);
          if (item) {
            // 处理判断下回填数据
            const data = this.businessContractList.filter((v) => v.orderId === item.orderId);
            if (data.length === 0) {
              this.businessContractList.push(item);
            }
            if (data.length === 1) {
              this.$emit('update-writeoff', data[0], this.index);
            }
          }
        },
      });
    },
    changeBusinessContract(item) {
      this.$emit('change', item);
    },
  },
};
</script>

<style></style>
