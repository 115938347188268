<template>
  <div>
    <el-select v-model="sellStm" value-key="sellStmtNo" placeholder @change="changeSellStm">
      <el-option v-for="item in sellStmList" :key="item.sellStmtNo" :label="item.sellStmtNo" :value="item">
        <span>{{ item.sellStmtNo }}{{ item.remark ? '(' + item.remark + ')' : '' }} </span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ['value', 'orderId'],
  data() {
    return {
      sellStm: null,
      sellStmList: [],
    };
  },
  watch: {
    orderId: {
      handler() {
        this.getSellStmList(this.orderId);
      },
      immediate: true,
    },
    value: {
      handler() {
        this.sellStm = JSON.parse(JSON.stringify(this.value));
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getSellStmList(orderId) {
      this.ajax({
        type: 'GET',
        url: `/malicrm/fmSellStmt/queryActiveStmtByOrderId/${orderId}`,
        loading: true,
        success: (res) => {
          this.sellStmList = res.result;
        },
      });
    },
    changeSellStm(item) {
      this.$emit('change', item);
    },
  },
};
</script>

<style></style>
