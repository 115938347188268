<template>
  <div>
    <div v-show="!incidentalsVisable">
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">关联收款订单</div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">提交审核</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="order-base__warp op-16">
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12">
            <erp-view label="付方名称" :value="detailsData.payerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="交易时间" :value="detailsData.transactionTime"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款金额" :value="detailsData.amountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款方名称" :value="detailsData.principalFullName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="未关联收款金额" :value="detailsData.writtenOffAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="摘要" :value="detailsData.remark"></erp-view>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
        <div class="erp-form__title f16">收款订单</div>
      </div>
      <div class="claim_company">
        <el-table
          :data="formData.dtoList"
          border
          class="egrid erp-table__list erp-view__table table-loading"
          :show-summary="showSummary"
          :summary-method="summaryFn"
          :class="{ 'no-shadow': !shadow }"
          id="tables"
          ref="tables"
          v-loading="loading"
        >
          <el-table-column label="销售合同号" class-name="no-pd" align="center" width="190">
            <template v-slot:header>
              <span class="erp-req">销售合同号</span>
            </template>
            <template #default="scope">
              <business-contract
                :value="scope.row.businessContractNo"
                :index="scope.$index"
                @change="changeBusinessContract($event, scope)"
                @update-writeoff="updateWriteOffAmount"
              >
              </business-contract>
            </template>
          </el-table-column>
          <el-table-column label="签约主体" class-name="no-pd" align="center" :show-overflow-tooltip="true">
            <template #default="scope">
              <div>{{ filterName(scope.row.principalName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="收款类型" class-name="no-pd" align="center" width="126">
            <template v-slot:header>
              <span class="erp-req">收款类型</span>
            </template>
            <template #default="scope">
              <el-select v-model="scope.row.infundType" placeholder @change="changeInfund($event, scope)">
                <el-option v-for="item in Options.infund_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="客户" class-name="no-pd" align="center" :show-overflow-tooltip="true">
            <template #default="scope">
              <div>{{ filterName(scope.row.customerName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="销售结算单号" align="center" class-name="no-pd" width="130">
            <template #default="scope">
              <sellStmSelect
                :value="scope.row.sellStm"
                :orderId="scope.row.orderId"
                @change="changeSellStm($event, scope)"
                v-if="scope.row.infundType === 2"
              ></sellStmSelect>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="收款细目" prop="varietyName" align="center" class-name="no-pd" width="100">
            <template v-slot:header>
              <span class="erp-req">收款细目</span>
            </template>
            <template #default="scope">
              <el-input
                v-model="scope.row.sundryFeeTypeDesc"
                placeholder
                v-if="scope.row.infundType === 4 || scope.row.infundType === 3"
                @click="showIncidentals(scope.row, scope.$index)"
                :readonly="true"
              ></el-input>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="结算金额" align="center" show-overflow-tooltip width="130">
            <template #default="scope">
              <div>{{ filterName(scope.row.expectAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="核销金额" prop="contractWeightDisplay" align="center" class-name="no-pd" width="110">
            <template v-slot:header>
              <span class="erp-req">核销金额</span>
            </template>
            <template #default="scope">
              <el-input v-model="scope.row.actualAmount" placeholder :maxlength="11" @blur="changePrice($event, scope.row, 'actualAmount')"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="center" class-name="no-pd">
            <template #default="scope">
              <el-input v-model="scope.row.remark" placeholder :maxlength="100"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="48" class-name="no-pd" align="center">
            <template v-slot:header>
              <span class="el-icon-circle-plus" style="font-size: 18px; display: flex; align-items: center; justify-content: center" @click="addProduct"></span>
            </template>
            <template #default="scope">
              <span class="list-del" @click="delItem(scope.row, scope.$index)">
                <img src="../../../../assets/images/list-del.png" alt />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--选择杂费类型 -->
    <Incidentals ref="incidentalsList" v-show="incidentalsVisable" :data="activeRow" @cancel="cancelIncidentals" @checked="getCheckIncidentals"></Incidentals>
  </div>
</template>
<script>
import Incidentals from './components/Incidentals.vue';
import businessContract from './components/businessContract.vue';
import sellStmSelect from './components/sellStmSelect.vue';
import { store } from '@/store';
export default {
  components: {
    Incidentals,
    businessContract,
    sellStmSelect,
  },
  props: {
    type: { type: [String, Number] },
    detailsData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      Options: {
        infund_type: store.state.dict.options.infund_type,
      },
      shadow: true,
      businessContractList: [],
      sellStmList: [], // 销售结算单列表
      formData: {
        dtoList: [
          {
            businessContractNo: null, // 自定义销售合同对象
            compareBusinessContractNo: null,
            businessContractNoId: null,
            customerName: null, // 自定义字段
            sellStm: null, // 销售结算单对象
            actualAmount: null,
            expectAmount: null,
            sundryFeeTypeDesc: null, // 自定义字段
            principalName: null,
            id: null,
            infundId: this.detailsData.infundId,
            infundType: null,
            orderArapDetailId: null,
            orderId: null,
            remark: null,
            sellStmtId: null,
            sellStmtNo: null,
            sundryFeeType: null,
            writeOffAmount: null,
          },
        ],
        infundId: this.detailsData.infundId,
        version: this.detailsData.version,
      },
      tmpIndex: null, // 记录下点击显示的是哪一行
      incidentalsVisable: false,
      activeRow: null,
      actualAmountTotal: null,
      ajaxLoading: false,
    };
  },
  computed: {
    showSummary() {
      return this.formData.dtoList.length >= 2;
    },
  },
  created() {
    if (this.type === 2) {
      this.initData();
    }
  },

  methods: {
    initData() {
      if (this.detailsData.waitAudit && this.detailsData.waitAudit.listVo && this.detailsData.waitAudit.listVo.length > 0) {
        const data = JSON.parse(JSON.stringify(this.detailsData.waitAudit.listVo));
        console.log("🚀 ~ file: auditOrder.vue ~ line 223 ~ initData ~ data", data);
        data.forEach((v) => {
          v.compareBusinessContractNo = v.businessContractNo;
          v.businessContractNo = {
            customerName: v.customerName,
            orderId: v.orderId,
            businessContractNo: v.businessContractNo,
          };
          v.sellStm = {
            sellStmtNo: v.sellStmtNo,
            id: v.sellStmtId,
            pickupAmount: v.expectAmount,
          };
          v.principalName = v.contractPrincipalName;
        });
        this.formData.dtoList = data;
      }
    },

    changeSellStm(item, scope) {
      // 选择销售结算单号
      const { $index } = scope;
      const data = this.formData.dtoList[$index];
      data.sellStmtNo = item.sellStmtNo; // 写入销售合同号
      data.sellStmtId = item.id; // 写入销售合同号
      data.expectAmount = item.pickupAmount; // 2.如果收款类型为货款，结算金额=对应销售结算单的提货款
      data.expectAmountDesc = item.pickupAmount; // 2.如果收款类型为货款，结算金额=对应销售结算单的提货款
      data.sundryFeeType = null;
      data.sundryFeeTypeDesc = null;
    },
    getBusinessContract(name) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/order/queryActiveOrder',
        data: {
          businessContractNo: name,
          isFundingType: false,
        },
        loading: true,
        success: (res) => {
          this.businessContractList = res.result;
        },
      });
    },
    changeBusinessContract(item, scope) {
      const { $index } = scope;
      const data = this.formData.dtoList[$index];
      data.settleType = item.settleType;
      data.compareBusinessContractNo = item.businessContractNo;
      data.category = item.category;
      data.isNeedJr = item.isNeedJr; // 是否金融服务
      data.customerName = item.customerName; // 写入客户名称
      data.orderId = item.orderId; // 写入订单id
      data.writeOffAmount = item.writeOffAmount; // 已核销金额
      data.principalName = item.principalName;
      data.contractPrincipalId = item.contractPrincipalId;
      data.infundType = null;
      data.expectAmount = null;
      data.expectAmountDesc = null;
      data.sellStm = null;
      data.sellStmtNo = null;
      data.sellStmtId = null;
      data.expectAmount = null;
      data.sundryFeeType = null;
      data.sundryFeeTypeDesc = null;
    },
    updateWriteOffAmount(item, index) {
      const data = this.formData.dtoList[index];
      data.writeOffAmount = item.writeOffAmount; // 已核销金额
    },
    cancelIncidentals() {
      // 杂费显示关闭
      this.incidentalsVisable = false;
    },
    getCheckIncidentals(data) {
      // 选择杂费获取信息
      this.incidentalsVisable = false;
      const item = this.formData.dtoList[this.tmpIndex];
      item.orderArapDetailId = data.orderArapDetailId;
      item.sundryFeeType = data.feeType;
      item.sundryFeeTypeDesc = data.feeTypeDesc;
      item.expectAmount = data.amountRmb; // 3.如果收款类型为杂费，结算金额为已选中杂费的金额（人民币）
      item.expectAmountDesc = data.amountRmbDesc; // 3.如果收款类型为杂费，结算金额为已选中杂费的金额（人民币）
    },
    showIncidentals(item, index) {
      // 显示杂费选项
      this.activeRow = item;
      this.tmpIndex = index;
      if (item.orderId) {
        // 收款细目
        this.$refs.incidentalsList.getIncidentalsList(item.orderId, item.infundType);
        this.incidentalsVisable = true;
      } else {
        this.errorTip('请先选择销售合同');
      }
    },
    changeInfund(value, scope) {
      const { $index } = scope;
      const item = this.formData.dtoList[$index];
      item.sellStm = null;
      item.sellStmtId = null;
      item.sellStmtNo = null;
      item.sundryFeeTypeDesc = null;
      item.expectAmount = null;
      item.expectAmountDesc = null;
      item.sellStmtNo = null;
      if (value === 1 || value === 6) {
        // 如果为保证金
        this.getExpectAmountRmb(item.orderId, $index, value); // 通过id拿到保证金->结算金额
      }
      if (value !== 4) {
        item.sundryFeeType = null;
        item.sundryFeeTypeDesc = null;
      }

      if (value !== 2) {
        item.sellStm = null; // 清除销售合同号
        item.sellStmtId = null; // 清除销售合同号
        item.sellStmtNo = null; // 清除销售合同号
      }
    },
    getExpectAmountRmb(orderId, $index, type) {
      // 通过订单id获取结算金额
      const item = this.formData.dtoList[$index];
      this.ajax({
        type: 'GET',
        url: `/malicrm/order/queryOrderAmountInfoById/${orderId}`,
        loading: true,
        success: (res) => {
          if (res.result) {
            if (res.result.category === 1) {
              if (type === 1) {
                if (item.settleType === 2) {
                  item.expectAmount = res.result.expectAmountRmb; // 期货：结算金额=商机总货值*保证金比例*客户指定汇率 2020/03/05修改
                  item.expectAmountDesc = res.result.expectAmountRmbDesc;
                  // 期货：结算金额=商机总货值*保证金比例*客户指定汇率 2020/03/05修改
                } else {
                  item.expectAmount = res.result.expectAmountRmb; // 期货：结算金额=商机总货值*保证金比例*客户指定汇率（即客户框架合
                  item.expectAmountDesc = res.result.expectAmountRmbDesc;
                  // 期货：结算金额=商机总货值*保证金比例*客户指定汇率（即客户框架合
                }
              }
              if (type === 6) {
                // 如果收款类型为尾款(外商)，结算金额=订单实际货值合计(交易币种)-收款里的保证金(交易币种)
                item.expectAmount = res.result.infundFinalAmount; // 期货：结算金额=商机总货值*保证金比例*客户指定汇率（即客户框架合

                item.expectAmountDesc = res.result.infundFinalAmountDesc;
                // 期货：结算金额=商机总货值*保证金比例*客户指定汇率（即客户框架合
              }
            } else {
              // 期货显示
              if (type === 6) {
                console.log(type);
              } else {
                item.expectAmount = res.result.settleAmountInFund; // 如果是现货，结算金额=（商机总货值+90天利息）*保证金比例 1126修改

                item.expectAmountDesc = res.result.settleAmountInFundDesc;
                // 如果是现货，结算金额=（商机总货值+90天利息）*保证金比例 1126修改
              }
            }
          } else {
            item.expectAmount = null; // 期货：结算金额=商机总货值*保证金比例*客户指定汇率（即客户框架合
            item.expectAmountDesc = null; // 期货：结算金额=商机总货值*保证金比例*客户指定汇率（即客户框架合
          }
        },
      });
    },
    addProduct() {
      if (this.formData.dtoList.length >= 100) {
        this.$forceUpdate();
        this.$refs.tables.doLayout();
        return false;
      } else {
        const data = {
          businessContractNo: null, // 自定义销售合同对象
          compareBusinessContractNo: null,
          businessContractNoId: null,
          customerName: null, // 自定义字段
          sellStm: null, // 销售结算单对象
          actualAmount: null,
          expectAmount: null,
          id: null,
          infundId: this.detailsData.infundId,
          infundType: null,
          orderArapDetailId: null,
          orderId: null,
          remark: null,
          sellStmtId: null,
          sellStmtNo: null,
          sundryFeeType: null,
          sundryFeeTypeDesc: null, // 自定义字段
          writeOffAmount: null,
        };
        this.formData.dtoList.push(data);
        this.$forceUpdate();
      }
    },
    delItem(item, index) {
      if (
        !item.businessContractNo &&
        !item.infundType &&
        !item.apPickupAmount &&
        !item.customerName &&
        !item.sellStm &&
        !item.sundryFeeTypeDesc &&
        !item.expectAmount &&
        !item.actualAmount &&
        !item.remark
      ) {
        this.delCofirm(item, index);
      } else {
        this.$erpConfirm('确定删除吗？')
          .then(() => {
            this.delCofirm(item, index);
          })
          .catch(() => {
            return false;
          });
      }
    },
    delCofirm(item, index) {
      const data = this.formData.dtoList;
      data.splice(index, 1);
    },
    validateData() {
      const data = this.formData.dtoList;
      let status = true;
      if (data.length === 0) {
        this.errorTip('请录入数据');
        return false;
      }

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (!item.infundType || !item.orderId || item.actualAmount === '' || item.actualAmount === null) {
          this.errorTip('请填写所有带星号的必填项');
          status = false;
          break;
        }
        if (item.infundType === 4 || item.infundType === 3) {
          // 杂费类型必填
          if (!item.sundryFeeType) {
            this.errorTip('请填写所有带星号的必填项');
            status = false;
            break;
          }
        }
        if (item.infundType === 2) {
          if (!item.sellStmtNo) {
            this.errorTip('请填写销售结算单号');
            status = false;
            break;
          }
        }
        console.log("🚀 ~ file: auditOrder.vue ~ line 478 ~ validateData ~ item.contractPrincipalId", item.contractPrincipalId,this.detailsData.contractPrincipalId);
        if (item.contractPrincipalId !== this.detailsData.contractPrincipalId) {
          this.errorTip('销售合同号对应的签约主体与收款方不一致');
          status = false;
          break;
        }
      }
      return status;
    },
    /**
     *  校验相同销售合同号下的核销金额(actualAmount)合计加上已核销金额大于等于0
     */
    validWriteOff() {
      let result = true;
      const arrObj = this.formData.dtoList;

      const totalActualAmount = arrObj.reduce((total, item) => {
        const price = Number(item.actualAmount);
        return total + price;
      }, 0);
      if (totalActualAmount < 0) {
        result = false;
        this.errorTip('合计核销金额小于0');
      }
      return result;
    },
    /**
     * 提交事件
     */
    submit() {
      const status = this.validateData();
      if (status) {
        const valid = this.validWriteOff();
        if (!valid) {
          return;
        }
      }
      if (status) {
        const data = this.formData.dtoList;
        let actualAmountTotal = data.reduce((total, item) => {
          const price = Number(item.actualAmount);
          return total + price;
        }, 0);
        actualAmountTotal = this._formatFloat(actualAmountTotal, 2);
        if (actualAmountTotal > this.detailsData.writtenOffAmount) {
          this.errorTip('合计核销金额大于未关联收款金额');
        } else {
          if (!this.ajaxLoading) {
            this.ajaxLoading = true;
            this.ajax({
              type: 'POST',
              url: '/malicrm/fmInfund/saveArAudit',
              data: this.formData,
              success: (res) => {
                if (res.code === 200) {
                  this.successTip('提交成功');
                  this.$emit('cancel', true);
                  setTimeout(() => {
                    this.ajaxLoading = false;
                  }, 500);
                } else {
                  this.errorTip(res.message);
                  this.ajaxLoading = false;
                }
              },
            });
          }
        }
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    summaryFn() {
      const summaryColumns = ['合计', '-', '-', '-', '-', '-', '-', '-'];
      const data = this.formData.dtoList;
      let expectAmountTotal = data.reduce((total, item) => {
        const price = Number(item.expectAmount);
        return total + price;
      }, 0);

      let actualAmountTotal = data.reduce((total, item) => {
        const price = Number(item.actualAmount);
        return total + price;
      }, 0);
      expectAmountTotal = this._formatFloat(expectAmountTotal, 2);
      actualAmountTotal = this._formatFloat(actualAmountTotal, 2);
      if (isNaN(expectAmountTotal)) {
        expectAmountTotal = 0;
      }
      if (isNaN(actualAmountTotal)) {
        actualAmountTotal = 0;
      }

      summaryColumns[5] = this._thousandBitSeparator(expectAmountTotal);
      summaryColumns[6] = this._thousandBitSeparator(actualAmountTotal);
      return summaryColumns;
    },
    changePrice(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 9999999.99) {
        value = String(9999999.99);
      }
      if (value < -9999999.99) {
        value = String(-9999999.99);
      }
      if (value.indexOf('-') === 0) {
        value = value.replace(/-/g, ''); // 只保留第一个. 清除多余的
        if (value.length >= 1) {
          value = '-' + value;
        }
      } else {
        value = value.replace(/-/g, '');
      }
      item[name] = value ? Number(value) : null;
    },
  },
};
</script>
<style lang="scss" scoped></style>
