<template>
  <div class="payment-account-form">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">收款备注</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="24">
              <el-form-item label="备注" prop="memo" class="no-asterisk">
                <el-input :maxlength="100" v-model="formData.memo" clearable resize="none" placeholder="请输入备注" type="textarea"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: [Object],
    },
  },
  data() {
    return {
      rules: {
        // 检验规则
        memo: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      formData: {
        infundId: null,
        memo: '',
        version: null,
      },
    };
  },
  created() {
    this.formData.infundId = this.data.infundId;
    this.formData.version = this.data.version;
    this.formData.memo = this.data.memo;
  },
  methods: {
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.ajax({
            type: 'POST',
            url: '/malicrm/fmInfund/saveInfundMemo',
            data: this.formData,
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('保存成功');
                this.$emit('cancel', true);
              }
            },
          });
        } else {
          this.errorTip('请填写备注');
        }
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
