<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <div class="erp-primary__btn" @click="editBaseInfo(1)" v-if="detailsData.waitAudit.listVo.length === 0 && hasPermission('erp:fmInfund:saveArAudit')">
        关联收款订单
      </div>
      <div class="erp-primary__btn" @click="getAudit" v-if="detailsData.waitAudit.listVo.length > 0 && hasPermission('erp:fmInfund:auditInfund')">审核</div>
      <div class="erp-primary__btn" @click="setRemark(detailsData)">备注</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">查看收款</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="付方名称" :value="detailsData.payerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="交易时间" :value="detailsData.transactionTime"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款方名称" :value="detailsData.principalFullName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款金额" :value="detailsData.amountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="未关联收款金额" :value="detailsData.writtenOffAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="摘要" :value="detailsData.remark"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--订单产品信息展示-->
    <div v-if="detailsData.waitAudit.listVo.length > 0">
      <div style="margin-top: 30px" class="order-title__bar op-16">
        <div class="erp-form__title f16">关联订单（待审核）</div>
        <div class="edit-btn__bar">
          <span class="edit-btn" @click="editBaseInfo(2)" v-if="hasPermission('erp:fmInfund:saveArAudit')"> <i class="icon-bg icon-form__edit"></i>编辑 </span>
          <span class="edit-btn" @click="cancelOrder" v-if="hasPermission('erp:fmInfund:saveArAudit')"> <i class="icon-bg icon-form__del"></i>取消 </span>
        </div>
      </div>
      <el-table :data="detailsData.waitAudit.listVo" border class="egrid erp-table__list erp-view__table">
        <el-table-column label="销售合同号" prop="plantNo" align="center" show-overflow-tooltip width="180">
          <template #default="scope">
            <div :class="{ record__link: scope.row.businessContractNo }" @click="linkTo(scope.row)">{{ filterName(scope.row.businessContractNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="签约主体" prop="contractPrincipalName" align="center" show-overflow-tooltip width="100">
          <template #default="scope">
            <div>{{ filterName(scope.row.contractPrincipalName) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收款类型" prop="nameCn" align="center" show-overflow-tooltip width="110">
          <template #default="scope">
            <div>{{ filterName(scope.row.infundTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户" prop="nameEn" align="center" show-overflow-tooltip width="180">
          <template #default="scope">
            <div class="text-ellipsis">{{ filterName(scope.row.customerName) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="销售结算单号" align="center" show-overflow-tooltip width="150">
          <template #default="scope">
            <div
              :class="{
                record__link: scope.row.excelUrl && scope.row.excelUrl !== '',
              }"
              @click="$showFiles(scope.row.excelName, scope.row.excelUrl, true, true)"
            >
              {{ filterName(scope.row.sellStmtNo) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="杂费类型" prop="varietyName" align="center" show-overflow-tooltip width="110">
          <template #default="scope">
            <div>{{ filterName(scope.row.sundryFeeTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结算金额" prop="unitPriceDisplay" align="center" show-overflow-tooltip width="130">
          <template #default="scope">
            <div>{{ filterName(scope.row.expectAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="核销金额" prop="contractWeightDisplay" align="center" show-overflow-tooltip width="130">
          <template #default="scope">
            <div>{{ filterName(scope.row.actualAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="contractAmountDisplay" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip width="180">
          <template #default="scope">
            <div>{{ filterName(scope.row.createdTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建人" align="center" show-overflow-tooltip width="140">
          <template #default="scope">
            <div>{{ filterName(scope.row.creator) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 审核已通过 -->
    <div v-show="detailsData.confirmList && detailsData.confirmList.length > 0">
      <div style="margin-top: 30px" class="order-title__bar op-16">
        <div class="erp-form__title f16">关联订单（审核已通过）</div>
      </div>
      <div class="claim_company">
        <el-table
          :show-summary="showOrderListSummary"
          :summary-method="getSummaries"
          :data="detailsData.confirmList"
          border
          :class="{ 'no-shadow': !shadow }"
          id="confirmListTable"
          class="egrid erp-table__list erp-view__table"
        >
          <el-table-column label="销售合同号" prop="plantNo" align="center" show-overflow-tooltip width="140">
            <template #default="scope">
              <div :class="{ record__link: scope.row.businessContractNo }" @click="linkTo(scope.row)">{{ filterName(scope.row.businessContractNo) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="签约主体" prop="contractPrincipalName" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.contractPrincipalName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="收款类型" prop="nameCn" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.infundTypeDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="客户" prop="nameEn" align="center" show-overflow-tooltip width="140">
            <template #default="scope">
              <div class="text-ellipsis">{{ filterName(scope.row.customerName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="销售结算单号" align="center" show-overflow-tooltip width="130">
            <template #default="scope">
              <div
                :class="{
                  record__link: scope.row.excelUrl && scope.row.excelUrl !== '',
                }"
                @click="$showFiles(scope.row.excelName, scope.row.excelUrl, true, true)"
              >
                {{ filterName(scope.row.sellStmtNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="杂费类型" prop="varietyName" align="center" show-overflow-tooltip width="110">
            <template #default="scope">
              <div>{{ filterName(scope.row.sundryFeeTypeDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="结算金额" prop="expectAmount" align="center" show-overflow-tooltip width="130">
            <template #default="scope">
              <div>{{ filterName(scope.row.expectAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="核销金额" prop="actualAmount" align="center" show-overflow-tooltip width="130">
            <template #default="scope">
              <div>{{ filterName(scope.row.actualAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="contractAmountDisplay" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.remark) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" show-overflow-tooltip width="178">
            <template #default="scope">
              <div>{{ filterName(scope.row.createdTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建人" align="center" show-overflow-tooltip width="90">
            <template #default="scope">
              <div>{{ filterName(scope.row.creator) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="审核人" align="center" show-overflow-tooltip width="90">
            <template #default="scope">
              <div>{{ filterName(scope.row.auditOperator) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="审核说明" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.auditDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label align="center" width="48">
            <template #default="scope">
              <span class="list-del" @click="delAudit(scope.row)" v-if="hasPermission('erp:fmInfund:deleteInfund')">
                <img src="../../../../assets/images/list-del.png" alt />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 收款备注 -->
    <div v-if="detailsData.memo && detailsData.memo !== ''">
      <div style="margin-top: 30px" class="order-title__bar op-16">
        <div class="erp-form__title f16">收款备注</div>
      </div>
      <div style="padding: 0 28px">
        <erp-view type="txt" :value="detailsData.memo" style="padding-top: 0px"></erp-view>
      </div>
    </div>
    <!-- 关联收款订单 -->
    <erp-drawer :visible="visible && type < 3" @close="closeDialog" erp class="no-padding fix-top-header" width="1136">
      <audit-order :detailsData="detailsData" @cancel="closeDialog" :type="type" v-if="type < 3"></audit-order>
    </erp-drawer>
    <erp-drawer :visible="visible && type >= 3" @close="closeDialog" erp class="no-padding fix-top-header" width="1110">
      <detail-remark @cancel="closeDialog" :data="detailsData" v-if="type >= 3"></detail-remark>
    </erp-drawer>
  </div>
</template>

<script>
import { getRouterParams, loadPage } from '@/utils/publicMethods';
import auditOrder from './auditOrder.vue';
import DetailRemark from './components/detailRemark.vue';
export default {
  name: 'collectionDetail',
  components: {
    auditOrder,
    DetailRemark,
  },
  props: {},
  data() {
    return {
      shadow: true,
      detailsData: {
        amount: null,
        amountDesc: null,
        confirmList: [],
        flowNo: null,
        isBusinessRelated: false,
        otherBusnam: null,
        otherBusnar: null,
        otherChknbr: null,
        otherNusage: null,
        otherReqnbr: null,
        otherTrscod: null,
        otherYurref: null,
        payerAccountArea: null,
        payerAccountName: null,
        payerBankAddress: null,
        payerBankName: null,
        payerBankNo: null,
        payerName: null,
        remark: null,
        transactionTime: null,
        waitAudit: {
          listVo: [],
        },
        writeOffStatus: null,
        writtenOffAmount: null,
        writtenOffAmountDesc: null,
      },
      infundId: null,
      version: null,
      visible: false, // 侧栏弹出显示
      type: 0,
    };
  },
  computed: {
    showOrderListSummary() {
      return this.detailsData.confirmList.length > 1;
    },
  },
  methods: {
    linkTo(row) {
      loadPage('OrderDetail', {
        orderId: row.orderId,
        version: row.version,
        name: 'collection',
      });
    },
    setRemark() {
      // 打开备注框

      this.type = 3;
      this.visible = true;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const noshow = [1, 2, 3, 4, 7, 8, 9, 10, 11, 12];
        if (noshow.includes(index)) {
          sums[index] = '';
          return;
        }
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (~String(sums[index]).indexOf('.')) {
            sums[index] = parseFloat(sums[index]).toFixed(2);
          }
          sums[index] = String(sums[index]).replace(reg, '$1,');
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    closeDialog(status) {
      this.visible = false;
      if (status) {
        this.getCollectionData(this.infundId);
      }
    },
    getCollectionData(infundId) {
      // 获取列表信息
      this.ajax({
        type: 'GET',
        url: `/malicrm/fmInfund/queryFmInfundDetailById/${infundId}`,
        success: (res) => {
          if (res.code === 200) {
            this.detailsData = res.result;
            this.version = res.result.version;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getAudit() {
      // 跳转到审核详情
      loadPage('collectionAuditDetail', {
        id: this.infundId,
        version: this.version,
        path: 'collectionDetail',
      });
    },
    cancelOrder() {
      // 取消审核
      this.$erpConfirm('确定取消本次审核吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/fmInfund/cancelInfundAudit',
            data: {
              infundId: this.infundId,
              version: this.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.getCollectionData(this.infundId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    delAudit(row) {
      this.$erpConfirm('确定删除吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/fmInfund/deleteInfund',
            data: {
              id: row.id,
              infundId: this.infundId,
              orderId: row.orderId,
            },
            success: (res) => {
              if (res.code === 200) {
                this.getCollectionData(this.infundId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      // 返回
      this.$router.push('/erp/financial/collection');
    },
    editBaseInfo(type) {
      this.type = type;
      this.visible = true;
    },
  },
  created() {
    const { id, version } = getRouterParams('collectionDetail');
    this.infundId = id;
    this.version = version;
    this.getCollectionData(id);
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
</style>
